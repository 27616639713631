import { userAxios } from '@/services'

const itemCategoryList = async (perPage) => {
    try {
        return await userAxios.get(`item_category/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCategoryCreate = async (data) => {
    try {
        return await userAxios.post('item_category', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCategoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`item_category/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCategoryDelete = async (id) => {
    try {
        return await userAxios.delete(`item_category/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCategoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`item_category/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCategoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`item_category/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const itemCategoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`item_category/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
export default {
    itemCategoryList,
    itemCategoryCreate,
    itemCategoryUpdate,
    itemCategoryDelete,
    itemCategoryPagination,
    itemCategoryFilter,
    itemCategoryFilterPagination
}